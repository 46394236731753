<template>
  <div>
    
    <!--  -->
    <div class="landing section">
      <!-- <b-navbar toggleable="lg" class="nav-ctm">
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav class="mx-auto mt-3">
            <b-navbar-brand href="/" class="mr-5"><img src="../assets/logo1x1.png" style="width: 40px"></b-navbar-brand>
            <b-nav-item href="/info">Party Revive</b-nav-item>
            <b-nav-item href="/tickets">Tickets</b-nav-item>
            <b-nav-item href="/fotos">Fotos</b-nav-item>
            <b-nav-item href="/contacto">Contacto</b-nav-item>
            <b-nav-item href="/login">Mi cuenta</b-nav-item>
            <b-navbar-brand href="/"><img src="../assets/logo1x1.png" style="width: 40px"></b-navbar-brand>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar> -->
      <div class="text-ctn">
        <b-row>
          <b-col sm="12" md="6" class="my-auto">
            <div>
              <h1 v-if="$store.state.isPartyRevive">PARTY <span class="green">RE</span><span class="blue">VI</span><span class="red">VE</span></h1>
              <h1 v-if="$store.state.isBuvac">BUVAC NIGHT</h1>
              <!-- <h1 v-if="$store.state.isTheBoss">THE BOSS</h1> -->
              <h4 v-if="$store.state.isPartyRevive">ERES TÚ Y LO COMPONEMOS TODOS</h4>
              <div class="px-5 mb-5">
                <img class="w-75" v-if="$store.state.isBuvac" src="https://i.ibb.co/MCqSGyv/logo-buvac.png"/>
                <img class="w-75" v-if="$store.state.isTheBoss" src="https://i.ibb.co/7rJ5VNd/theboss-logo-negro.png"/>
              </div>
              <img class="cartel d-block-xs" style="margin: auto" :src="event.poster"/>
              <b-btn v-for="btn in getButtons" :key="btn.paymentLink" variant="info" class="mt-4" :href="btn.paymentLink">COMPRAR ENTRADA {{ btn.name }} {{ btn.price }}€</b-btn>
              <br>
            </div>
          </b-col>
          <b-col sm="12" md="6" class="d-block-md">
            <img class="cartel" :src="event.poster"/>
          </b-col>
        </b-row>
        
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Entradas',
  data () {
    return {
      event: null
    }
  },
  mounted () {
    const eventId = this.$route.params.eventId
    this.axios.get(`/events${eventId ? `/${eventId}` : ''}`, { params: { owner: this.$store.state.domain, eventType: 1, lists: false }}).then(response => {
      if (!response.data || response.data.length == 0) {
        if (!eventId) {
          this.axios.get('/events', { params: { owner: this.$store.state.domain }}).then(response => {
            if (response.data && response.data.length > 0) {
              this.$router.push(`/listas/${response.data[0].id}`)
            } else {
              this.$router.push('/home')
            }
          })
        } else {
          this.$router.push(`/listas${eventId ? `/${eventId}` : ''}`)
        }
      } else {
        if (eventId) {
          this.event = response.data
        } else {
          this.event = response.data[0]
        }
      }
/*       
      this.form.eventId = this.events[0].id */
    })
  },
  computed: {
    getButtonPaymentLinks () {
      if (this.event) {
        return this.event.stripE_PAYMENT_LINKS.split(',')
      } else {
        return []
      }
    },
    getButtonNames () {
      if (this.event) {
        return this.event.buttoN_PRODUCT_NAMES.split(',')
      } else {
        return []
      }
    },
    getButtonPrices () {
      if (this.event) {
        return this.event.buttoN_PRODUCT_PRICES.split(',')
      } else {
        return []
      }
    },
    getButtons () {
      const buttons = []
      for (let i = 0; i < this.getButtonPaymentLinks.length; i++) {
        buttons.push({
          paymentLink: this.getButtonPaymentLinks[i],
          name: this.getButtonNames[i],
          price: this.getButtonPrices[i]
        })
      }
      return buttons
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '../assets/scss/global.scss';


.d-block-xs {
  @include mobile {
    display:block;
  }
  @include media(desktop, tablets){
    display: none;
  }
}

.d-block-md {
  @include mobile {
    display:none;
  }
  @include media(desktop, tablets){
    display: block;
  }
}
/* .navbar-nav{
    margin: 0 auto;
    display: table;
    table-layout: fixed;
    float:none;
} */

.nav-ctm {
  background-color: transparent;

  .nav-item {
    text-transform: uppercase;
    font-weight: bold;
    &:not(:last-child) {
      margin-right: 2rem;
    }
    a {
      color: white !important;
      &:hover {
        transition: .5s all ease-in;
        color: $red !important;
      }
    }

    &:hover {
        transition: .5s all ease-in;
        color: $red;
        padding-bottom: 5px;
        border-bottom: 1px solid $red;
      }
  }
}

.section {
    height: 100vh;
  }

.landing {
  height: 100vh;
  text-align: center;
  .text-ctn {
    display:flex;
    align-items: center;
    justify-content: center;

    @include media(desktop, tablets) {
      height: 80vh;
    }
    @include mobile {
      padding-top: 6rem;
      padding: {
        right: 1rem;
        left: 1rem;
      }
    }

    .cartel {
      width: 70%;
      box-shadow: 0px 0px 10px rgba(0,0,0,0.5);
    }

    h1 {
      color: white;
      @include media(desktop, tablets) {
        font-size: 5rem;
      }
      @include mobile {
        font-size: 3rem;
      }
      margin-bottom: 2rem;
      text-shadow: 0px 0px 6px rgba(255,255,255,0.7);
    }

    h4 {
      color: #ffffffb2;
      margin-bottom: 2rem;
    }
  }
  
}
</style>
